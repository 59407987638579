import { createSimpleNamedObservableExpressionResolver } from '../types';
import { HTMLImageDataUrl } from '@trackback/widgets';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const htmlImageDataUrlResolver =
  createSimpleNamedObservableExpressionResolver<HTMLImageDataUrl>(
    'htmlImageDataUrl',
    (widgetId, format) => {
      const DOM = document.getElementById(String(widgetId));
      return from(import('html2canvas')).pipe(
        switchMap(html2Canvas => html2Canvas.default(DOM)),
        map(canvas => canvas.toDataURL(`image/${format.toLowerCase()}`))
      );
    }
  );
