import { isEqual } from 'lodash-es';
import type { AllEqualInExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const equalResolver =
  createSimpleNamedExpressionResolver<AllEqualInExpression>('equal', values => {
    for (let i = 0; i < values.length - 1; i++) {
      for (let j = i + 1; j < values.length; j++) {
        const a = values[i];
        const b = values[j];

        if (!isEqual(a, b) && !isLooselySameNumber(a, b)) {
          return false;
        }
      }
    }
    return true;
  });

const isLooselySameNumber = (a: unknown, b: unknown) =>
  ((typeof a === 'number' && typeof b === 'string') ||
    (typeof a === 'string' && typeof b === 'number')) &&
  Number(a) === Number(b);
