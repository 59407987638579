import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarDismiss as MatSnackBarDismiss,
} from '@angular/material/legacy-snack-bar';
import { Navigate as RouterNavigate } from '@ngxs/router-plugin';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Resolved } from '@trackback/widgets';
import { saveAs } from 'file-saver';
import { produce } from 'immer';
import { assign, clone, union } from 'lodash-es';
import { EMPTY, from, throwError } from 'rxjs';
import { catchError, share, switchMap, take } from 'rxjs/operators';
import { LocalActionDispatcher } from '@app/models/action-dispatcher.model';
import * as WidgetActions from './widgets.actions';
import { DeclareGlobalActionResult } from './widgets.actions';
import { safeParseJson } from '@app/utils/safe-parse-json';
import { getType } from '@app/utils/type-registry';

export interface WidgetsStateModel {
  aliases: AliasMap;
  dispatchers: DispatcherMap;
  outputs: OutputMap;
  lastGlobalActionResult?: any;
}

@State<WidgetsStateModel>({
  name: 'widgets',
  defaults: {
    aliases: {},
    outputs: {},
    dispatchers: {},
  },
})
@Injectable()
export class WidgetsState {
  @Selector()
  public static getAliases(state: WidgetsStateModel) {
    return state.aliases;
  }

  @Selector()
  public static getOutputs(state: WidgetsStateModel) {
    return state.outputs;
  }

  @Selector()
  public static getAliasedOutputs(state: WidgetsStateModel) {
    const aliasedOutputs = clone(state.outputs);
    Object.keys(state.aliases).forEach(key => {
      if (typeof state.outputs[state.aliases[key]] === 'undefined') {
        aliasedOutputs[state.aliases[key]] = state.outputs[key];
      }
    });
    return aliasedOutputs;
  }

  @Selector()
  private static getDispatchers(state: WidgetsStateModel) {
    return state.dispatchers;
  }

  @Selector()
  public static getLastGlobalActionResult(state: WidgetsStateModel) {
    return state.lastGlobalActionResult;
  }

  @Selector([WidgetsState.getOutputs, WidgetsState.getDispatchers])
  public static getWidgetIds(
    _,
    outputs: OutputMap,
    dispatchers: DispatcherMap
  ): string[] {
    return union(Object.keys(outputs), Object.keys(dispatchers));
  }

  @Selector([WidgetsState.getOutputs, WidgetsState.getAliases])
  public static getOutputFn(
    _,
    outputs: OutputMap,
    aliases: AliasMap
  ): GetOutputFn {
    const fn = (widgetIds?: string | string[]) => {
      if (typeof outputs !== 'object' || outputs === null) {
        return undefined;
      } else if (Array.isArray(widgetIds)) {
        if (widgetIds.length === 0) {
          return undefined;
        } else {
          return widgetIds.reduce((prev, widgetId) => {
            prev[aliases[widgetId] || widgetId] = outputs[widgetId];
            return prev;
          }, {});
        }
      } else if (typeof widgetIds === 'string') {
        return outputs[widgetIds];
      } else {
        return undefined;
      }
    };
    return fn;
  }

  public static getWidgetOutput(widgetIds?: string | string[]) {
    const fn = createSelector(
      [WidgetsState.getOutputFn],
      (outputFn: GetOutputFn) => outputFn(widgetIds)
    );
    return fn;
  }

  @Selector([WidgetsState.getDispatchers])
  public static getDispatcherFn(
    _,
    dispatchers: DispatcherMap
  ): GetDispatcherFn {
    const fn = (widgetId?: string) => {
      if (typeof dispatchers === 'object' && typeof widgetId === 'string') {
        return dispatchers[widgetId];
      } else if (!widgetId) {
        return undefined;
      } else {
        return undefined;
      }
    };
    return fn;
  }

  @Action(WidgetActions.DeclareGlobalActionResult)
  declareActionResult(
    { patchState }: StateContext<WidgetsStateModel>,
    { result }: WidgetActions.DeclareGlobalActionResult
  ) {
    patchState({
      lastGlobalActionResult: result,
    });
  }

  @Action(WidgetActions.OpenDialog)
  openDialog(
    { dispatch }: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.OpenDialog
  ) {
    const { widget } = payload;
    const config = payload.config || {};
    const context = config.data;
    const data = {
      input: widget,
      context,
    };
    // Actions are dispatched outside the Angular zone for performance, to perform DOM related tasks such as opening a snackbar or a dialog,
    // we have to reenter the Angular zone manually
    return this.zone
      .run(() => this.dialog.open(getType(widget.type), { ...config, data }))
      .afterClosed()
      .pipe(
        switchMap(result => dispatch(new DeclareGlobalActionResult(result)))
      );
  }

  @Action(WidgetActions.Refresh)
  refresh() {
    window.location.reload();
    return EMPTY;
  }

  @Action(WidgetActions.CopyToClipboard)
  copyToClipboard(
    _state: StateContext<WidgetsStateModel>,
    { payload: { text } }: WidgetActions.CopyToClipboard
  ) {
    return navigator.clipboard.writeText(text as string);
  }

  @Action(WidgetActions.Navigate)
  navigate(
    { dispatch }: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.Navigate
  ) {
    return dispatch(new RouterNavigate(payload.path, payload.queryParams));
  }

  @Action(WidgetActions.OpenSimpleSnackbar)
  openSimpleSnackbar(
    { dispatch }: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.OpenSimpleSnackbar
  ) {
    const { text, action, config } = payload;
    // Actions are dispatched outside the Angular zone for performance, to perform DOM related tasks such as opening a snackbar or a dialog,
    // we have to reenter the Angular zone manually
    return this.zone
      .run(() => this.snackbar.open(String(text), action, config))
      .afterDismissed()
      .pipe(
        switchMap((result: MatSnackBarDismiss) =>
          dispatch(new DeclareGlobalActionResult(result.dismissedByAction))
        )
      );
  }

  @Action(WidgetActions.UpdateWidgetOutput)
  updateWidgetOutput(
    { setState, getState }: StateContext<WidgetsStateModel>,
    { widgetId, output }: WidgetActions.UpdateWidgetOutput
  ) {
    setState(
      produce(getState(), draft => {
        draft.outputs[widgetId] = assign(draft.outputs[widgetId] || {}, output);
      })
    );
  }

  @Action(WidgetActions.UpdateWidgetOutputs)
  updateWidgetOutputs(
    { setState, getState }: StateContext<WidgetsStateModel>,
    { outputs }: WidgetActions.UpdateWidgetOutputs
  ) {
    setState(
      produce(getState(), draft => {
        for (const { widgetId, output } of outputs) {
          draft.outputs[widgetId] = assign(
            draft.outputs[widgetId] || {},
            output
          );
        }
      })
    );
  }

  @Action(WidgetActions.ScrollIntoView)
  ScrollIntoView(
    _state: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.ScrollIntoView
  ) {
    const { id, block, inline } = payload,
      DomId = id as string;
    const elmnt = document.getElementById(DomId);
    const options: Record<string, unknown> = { behavior: 'smooth' };
    if (block) {
      options.block = block;
    }
    if (inline) {
      options.inline = inline;
    }
    elmnt.scrollIntoView(options);
  }

  @Action(WidgetActions.DownloadDocument)
  downloadDocument(
    { dispatch }: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.DownloadDocument
  ) {
    const { url } = payload,
      stringURL = url as string,
      remoteObservable = this.http
        .get(stringURL, {
          responseType: 'blob',
          observe: 'response',
        })
        .pipe(share());
    remoteObservable.subscribe(resp => {
      let fileName;
      if (resp.headers.get('content-disposition')) {
        let contentDisposition = resp.headers.get('content-disposition');
        contentDisposition = contentDisposition
          .split(';')[1]
          .trim()
          .split('=')[1];
        fileName = contentDisposition.replace(/"/g, '');
      } else {
        fileName = 'unknown_file';
      }
      saveAs(resp.body, fileName);
    });
    return remoteObservable.pipe(
      take(1),
      switchMap(result =>
        dispatch(new DeclareGlobalActionResult(result.status))
      ),
      catchError((errorResponse: HttpErrorResponse) =>
        from(errorResponse.error.text()).pipe(
          switchMap((errorText: string) =>
            throwError(
              new HttpErrorResponse({
                ...errorResponse,
                error: safeParseJson(errorText),
              })
            )
          )
        )
      )
    );
  }

  @Action(WidgetActions.SaveAsXLSX)
  SaveAsXLSX(
    _state: StateContext<WidgetsStateModel>,
    { payload }: WidgetActions.SaveAsXLSX
  ) {
    const { excelData, fileName } = payload;
    return import('xlsx').then(XLSX => {
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const data: Blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      saveAs(data, fileName);
    });
  }

  @Action(WidgetActions.ResetWidgetOutput)
  resetWidgetOutput(
    { setState, getState }: StateContext<WidgetsStateModel>,
    { widgetId, output }: WidgetActions.ResetWidgetOutput
  ) {
    setState(
      produce(getState(), draft => {
        if (output !== undefined) {
          draft.outputs[widgetId] = output;
        } else {
          delete draft.outputs[widgetId];
        }
      })
    );
  }

  @Action(WidgetActions.DeleteWidgetOutputs)
  deleteWidgetOutput(
    { setState, getState }: StateContext<WidgetsStateModel>,
    { payload: { widgetIds } }: WidgetActions.DeleteWidgetOutputs
  ) {
    setState(
      produce(getState(), draft => {
        (widgetIds as Resolved<typeof widgetIds>)?.forEach(
          widgetId => delete draft.outputs[widgetId as string]
        );
      })
    );
  }

  @Action(WidgetActions.RegisterWidget)
  registerWidget(
    { setState, getState }: StateContext<WidgetsStateModel>,
    {
      widgetId,
      widgetAlias,
      dispatcher,
      initialOutput,
    }: WidgetActions.RegisterWidget
  ) {
    if (widgetId !== undefined) {
      setState(
        produce(getState(), draft => {
          if (typeof widgetAlias === 'string') {
            draft.aliases[widgetId] = widgetAlias;
          }
          if (typeof dispatcher === 'function') {
            draft.dispatchers[widgetId] = dispatcher;
          }
          if (initialOutput !== undefined) {
            draft.outputs[widgetId] = initialOutput;
          }
        })
      );
    }
  }

  @Action(WidgetActions.RegisterWidgets)
  registerWidgets(
    { setState }: StateContext<WidgetsStateModel>,
    { widgets }: WidgetActions.RegisterWidgets
  ) {
    setState(
      produce(draft => {
        for (const { id, alias, dispatcher, initialOutput } of widgets) {
          if (typeof alias === 'string') {
            draft.aliases[id] = alias;
          }
          if (typeof dispatcher === 'function') {
            draft.dispatchers[id] = dispatcher;
          }
          if (initialOutput !== undefined) {
            draft.outputs[id] = initialOutput;
          }
        }
      })
    );
  }

  @Action(WidgetActions.DeregisterWidget)
  deregisterWidget(
    { setState, getState }: StateContext<WidgetsStateModel>,
    { widgetId, resetOutput }: WidgetActions.DeregisterWidget
  ) {
    if (widgetId) {
      setState(
        produce(getState(), draft => {
          delete draft.aliases[widgetId];
          delete draft.dispatchers[widgetId];
          if (resetOutput) {
            delete draft.outputs[widgetId];
          }
        })
      );
    }
  }

  @Action(WidgetActions.DeregisterWidgets)
  deregisterWidgets(
    { setState }: StateContext<WidgetsStateModel>,
    { widgets }: WidgetActions.DeregisterWidgets
  ) {
    setState(
      produce(draft => {
        for (const { id, resetOutput } of widgets) {
          delete draft.aliases[id];
          delete draft.dispatchers[id];
          if (resetOutput) {
            delete draft.outputs[id];
          }
        }
      })
    );
  }

  constructor(
    @Optional() private readonly dialog: MatDialog,
    @Optional() private readonly snackbar: MatSnackBar,
    @Optional() private readonly http: HttpClient,
    private readonly zone: NgZone
  ) {}
}

export type GetOutputFn = (
  widgetIds?: string | string[]
) => OutputMap | Record<string, any> | undefined;

export interface OutputMap {
  [widgetId: string]: Record<string, any>;
}

export type GetDispatcherFn = (
  widgetId: string
) => LocalActionDispatcher | undefined;

export interface DispatcherMap {
  [widgetId: string]: LocalActionDispatcher;
}

export interface AliasMap {
  [widgetId: string]: string;
}
